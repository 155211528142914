import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "reactstrap"
import { ContactForm, Layout } from "../components"

import {
  BannerMarketing,
  BlockImageTextHalf,
  Hashtags,
  IconNetworkSection,
  LogoHeading,
  ReferencesNext,
  ReferencesUsers,
  Slider,
  TextPrimary,
  TitleH3,
  VerticalLineListItem,
  VerticalLineListWrapper,
  already,
} from "@igloonet-web/shared-ui"

import IconChatBubble from "../icons/chat-bubble.svg"
import IconMonitorBasket from "../icons/monitor-basket.svg"
import IconSearch from "../icons/search.svg"

import ImageBlockIFE_1x from "../images/reference/ife/brand-image_1x.jpg"
import ImageBlockIFE_2x from "../images/reference/ife/brand-image_2x.jpg"
import ImageBlockKiwi_1x from "../images/reference/kiwi/brand-image_1x.jpg"
import ImageBlockKiwi_2x from "../images/reference/kiwi/brand-image_2x.jpg"

import ImageBlockSnowboardZezula from "../images/reference/snowboard-zezula/brand-image.jpg"
import LogoFSIVUT from "../images/reference/fsivut/logofsi.png"
import LogoHannah from "../images/reference/hannah/logo.png"
import LogoIfeSmall_1x from "../images/reference/ife/logoife_small_1x.png"
import LogoIfeSmall_2x from "../images/reference/ife/logoife_small_2x.png"

import LogoJena from "../images/reference/jena/logojena.png"
import LogoRegioJet from "../images/reference/student-agency/logo.svg"
import LogoTMobile from "../images/reference/t-mobile/logo.png"

import LogoHelcel from "../images/reference/helcel/logohelcel.png"
import HelcelPerson from "../images/reference/helcel/martin.jpg"

import LogoKiwi from "../images/reference/kiwi/logo.png"
import KiwiPerson from "../images/reference/kiwi/pezlarova.jpg"

import LogoFEKTVUT from "../images/reference/fektvut/logofekt.png"
import FEKTVUTPerson from "../images/reference/fektvut/avatar.jpg"

import LogoSnowboardZezula from "../images/reference/snowboard-zezula/logo.png"
import ZezulaPerson from "../images/reference/snowboard-zezula/reaction-marketing.png"

import ProfileAdam from "../images/team/profile/adam.jpg"
import ProfileLukas from "../images/team/profile/lukas.jpg"
import ProfileKlaudie from "../images/team/profile/klaudie.jpg"
import ProfileMyval from "../images/team/profile/myval.jpg"
import ProfileDavid from "../images/team/profile/david.jpg"
import { Helmet } from "react-helmet"

const TitleH3Custom = styled(TitleH3)`
  @media (max-width: ${(props) => props.theme.sizes_max.sm}) {
    background: ${(props) => props.theme.color.white};
    padding: 20px 15px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.color.gray300};
  }
`

const HalfBackgroundGray = styled.div`
  background-color: ${(props) => props.theme.color.gray200};
  box-shadow: inset 0 200px 0 0 ${(props) => props.theme.color.white};
`

const BorderBox = styled(Col)`
  background: ${(props) => props.theme.color.gray200};
  padding: 20px 15px;
  margin-left: -1px;

  @media (min-width: ${(props) => props.theme.sizes_min.sm}) {
    padding: 40px;
    border: 1px solid ${(props) => props.theme.color.gray300};
    background: ${(props) => props.theme.color.white};
  }
`

export default class Marketing extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: "1",
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    const marketingReferences = [
      {
        href: "/marketing/reference/helcel",
        imageSrc: LogoHelcel,
        imageAlt: "Logo Helcel nábytek",
      },
      {
        href: "/marketing/reference/hannah",
        imageSrc: LogoHannah,
        imageAlt: "Logo Hannah",
      },
      {
        imageSrc: LogoTMobile,
        imageAlt: "Logo T-Mobile",
      },
      {
        href: "/marketing/reference/student-agency",
        imageSrc: LogoRegioJet,
        imageAlt: "Logo Regiojet",
      },
      {
        href: "/marketing/reference/jena",
        imageSrc: LogoJena,
        imageAlt: "Logo JENA Nábytek",
      },
      {
        href: "/marketing/reference/fsivut",
        imageSrc: LogoFSIVUT,
        imageAlt: "Logo FSI VUT",
      },
    ]

    const usersReferences = [
      {
        id: 1,
        imageSrc: LogoKiwi,
        imagePerson: KiwiPerson,
        imageAlt: "Logo Kiwi.com",
        name: "Lucie Pezlarová",
        position: "Head of Analytics, Kiwi.com",
        linkHref: "/marketing/reference/kiwi",
        linkTitle: "s čím jsme pomáhali internímu týmu v Kiwi.com",
        text: "S Adamem spolupracujeme již od roku 2018. Pomáhá analytikům i marketingovým manažerům Kiwi.com nastavit vhodné postupy pro vyhodnocování celého portfolia marketingových aktivit tak, aby výsledný reporting dával smysl a hodnoty byly použitelné pro rozhodování. Adam je předním expertem a navíc důvěryhodným a organizovaným partnerem pro diskusi; spolupráce s ním si velmi vážím.",
      },
      {
        id: 2,
        imageSrc: LogoHelcel,
        imagePerson: HelcelPerson,
        imageAlt: "Logo Helcel nábytek",
        name: "Martin Helcel",
        position: "jednatel Helcel nábytek",
        linkHref: "/marketing/reference/helcel",
        linkTitle: "jak jsme převzali kampaně a zvýšili efektivitu o 19,5 %",
        text: "Požadavek na stabilní projektové řízení byl ze strany igloonetu splněn na 100 %. Považuji za velkou výhodu, že na projektu pracují stabilně stejní lidé, a vzájemně tak vždy víme, jaké jsou naše cíle. Velmi také oceňuji postřehy k možnému vylepšení celého projektu.",
      },
      {
        id: 3,
        imageSrc: LogoSnowboardZezula,
        imagePerson: ZezulaPerson,
        imageAlt: "Logo Snowboard Zezula",
        name: "Martin Zezula",
        position: "ředitel Snowboard Zezula",
        linkHref: "/marketing/reference/snowboard-zezula",
        linkTitle: "jak více než 10 let pomáháme SZ být jedničkou na trhu",
        text: "Na igloonetu se mi líbí spojení profesionality s neformálností. Abych řekl pravdu, tak jste nám vytrhli trn z paty.",
      },
      {
        id: 4,
        imageSrc: LogoFEKTVUT,
        imagePerson: FEKTVUTPerson,
        imageAlt: "Logo FEKT VUT",
        name: "doc. Ing. Jiří Háze, Ph.D.",
        position: "proděkan pro vnější vztahy a zahraniční styky",
        linkHref: "/marketing/reference/fektvut",
        linkTitle: "jak pomáháme vysokým školám s náborem studentů",
        text: "Spolupráce se společností igloonet je z našeho pohledu velmi přínosná. Musíme vyzdvihnout profesionalitu pracovníků firmy, jejich kreativitu a nápady, které pomáhají fakultě řešit problém nedostatku dobrých studentů na technické univerzitě. Díky spolupráci se fakultě daří uchazeče o studium oslovovat atraktivním způsobem a se zapálením pro elektrotechniku.",
      },
    ]

    const personSlider = [
      {
        id: 1,
        imageSrc: ProfileAdam,
        name: "Adam Šilhan",
        position: "10+ let zkušeností v online marketingu",
        text: "Google Certified Trainer oblasti Automation and Attribution, Exponea certifikace, hlavním záběrem je marketingová analytika, učí Webovou analytiku na Masarykově Univerzitě",
      },
      {
        id: 2,
        imageSrc: ProfileLukas,
        name: "Lukáš Havlík",
        position: "7 let zkušeností v online marketingu",
        text: "vede marketingový tým, garant pro sociální sítě, hlavním záběrem je komunikace a kampaně na sociálních sítích, mentoruje a školí klientské týmy",
      },
      {
        id: 3,
        imageSrc: ProfileKlaudie,
        name: "Klaudie Semelová",
        position: "6 let zkušeností v online marketingu",
        text: "garant pro PPC, vede větší marketingové projekty, má zkušenosti s komunikací jak s marcom, tak IT odděleními",
      },
      {
        id: 4,
        imageSrc: ProfileMyval,
        name: "Marek Pentoň",
        position: "6 let zkušeností ve vývoji / online marketingu",
        text: "garant pro e-mailing, Exponea certifikace, technický přesah - dělá na automatizačních nástrojích pro igloo (práce s APIs reklamních platforem)",
      },
      {
        id: 5,
        imageSrc: ProfileDavid,
        name: "David Pohorský",
        position: "10+ let zkušeností v online marketingu",
        text: "PPC specialista, analytické myšlení a no bullshit přístup, mentoruje a školí klientské týmy",
      },
    ]

    return (
      <Layout>
        <Helmet>
          <title>
            Online marketing – pomůžeme nastartovat váš byznys | igloonet
          </title>
          <meta
            name="description"
            content="Pomůžeme vašemu byznysu vydělávat. Tvoříme reklamní kampaně a marketingové strategie na míru."
          />
        </Helmet>

        <BannerMarketing
          title={"Budeme vám partnerem při plavbě mořem marketingu"}
          link={"#form"}
        >
          <p>
            Děláme marketing komplexně{" "}
            <b>od výzkumu přes kampaně až po datovou analytiku</b>. Fungujeme{" "}
            <b>po celé republice</b> a ať už si řešíte marketing{" "}
            <b>sami, nebo máte větší interní tým</b>, posuneme vás o ploutev dál
          </p>
        </BannerMarketing>

        <HalfBackgroundGray>
          <Container>
            <Row>
              <BorderBox lg={4}>
                <TitleH3Custom>
                  <IconChatBubble width={40} height={40} /> Podpora
                  marketingového týmu
                </TitleH3Custom>
                <VerticalLineListWrapper>
                  <VerticalLineListItem>Mentoring</VerticalLineListItem>
                  <VerticalLineListItem>Automatizace</VerticalLineListItem>
                  <VerticalLineListItem>Školení na míru</VerticalLineListItem>
                  <VerticalLineListItem>
                    Nastavení analytiky a měření
                  </VerticalLineListItem>
                  <VerticalLineListItem>
                    Propojování interních týmů
                  </VerticalLineListItem>
                  <VerticalLineListItem>
                    Marketingová strategie
                  </VerticalLineListItem>
                  <VerticalLineListItem>
                    Podpora managementu
                  </VerticalLineListItem>
                  <VerticalLineListItem>Výběrová řízení</VerticalLineListItem>
                </VerticalLineListWrapper>
              </BorderBox>

              <BorderBox lg={4}>
                <TitleH3Custom>
                  <IconSearch width={40} height={40} /> Analýzy a výzkum
                </TitleH3Custom>
                <VerticalLineListWrapper>
                  <VerticalLineListItem>Analýza trhu</VerticalLineListItem>
                  <VerticalLineListItem>
                    Mapování konkurence
                  </VerticalLineListItem>
                  <VerticalLineListItem>
                    Expanze do zahraničí
                  </VerticalLineListItem>
                  <VerticalLineListItem>Datová analytika</VerticalLineListItem>
                  <VerticalLineListItem>UX/CRO</VerticalLineListItem>
                  <VerticalLineListItem>
                    Příprava workshopů
                  </VerticalLineListItem>
                  <VerticalLineListItem>Tvorba person</VerticalLineListItem>
                  <VerticalLineListItem>Cesta zákazníka</VerticalLineListItem>
                </VerticalLineListWrapper>
              </BorderBox>

              <BorderBox lg={4}>
                <TitleH3Custom>
                  <IconMonitorBasket width={40} height={40} /> Online marketing
                </TitleH3Custom>
                <VerticalLineListWrapper>
                  <VerticalLineListItem>PPC</VerticalLineListItem>
                  <VerticalLineListItem>Sociální sítě</VerticalLineListItem>
                  <VerticalLineListItem>
                    Search / Vyhledávače
                  </VerticalLineListItem>
                  <VerticalLineListItem>Shopping</VerticalLineListItem>
                  <VerticalLineListItem>Mailing</VerticalLineListItem>
                  <VerticalLineListItem>
                    Zbožové srovnávače
                  </VerticalLineListItem>
                  <VerticalLineListItem>SEO</VerticalLineListItem>
                  <VerticalLineListItem>RTB</VerticalLineListItem>
                </VerticalLineListWrapper>
              </BorderBox>
            </Row>
          </Container>
        </HalfBackgroundGray>

        <IconNetworkSection />

        <Container className={"py-lg-5"}>
          <BlockImageTextHalf
            image={ImageBlockKiwi_1x}
            imageSrcSet={`${ImageBlockKiwi_1x},${ImageBlockKiwi_2x} 2x`}
            alt={"křídlo přepravního letadla v mracích"}
            width="538"
            height="357"
            buttonText={"Celý příběh"}
            buttonLink="/marketing/reference/kiwi"
          >
            <LogoHeading imagePath={LogoKiwi} imageAlt="Popisek loga">
              Propojujeme marketing a data pro{" "}
              <TextPrimary>Kiwi.com</TextPrimary>
            </LogoHeading>

            <p>
              Pro <b>Kiwi.com</b> jsme začali konzultacemi ohledně propojování
              marketingu s daty a analytiky. Konzultace jsme později rozšířili
              až k výkonnostním kampaním na socálních sítích a mailingu.
            </p>

            <Hashtags>#mentoring #analytika #e-mailing #data</Hashtags>
          </BlockImageTextHalf>

          <BlockImageTextHalf
            image={ImageBlockSnowboardZezula}
            alt={"Lidi sedí na zelených schodech s notebooky"}
            width="538"
            height="448"
            buttonText={"Celý příběh"}
            buttonLink="/marketing/reference/snowboard-zezula"
            right
          >
            <LogoHeading
              imagePath={LogoSnowboardZezula}
              imageAlt="Logo Snowboard Zezula"
            >
              Díky nám je <TextPrimary>Snowboard Zezula</TextPrimary> jedničkou
              na trhu
            </LogoHeading>

            <p>
              Se snowboarďáky děláme už {already(2007)}. Začali jsme jako
              vývojářská výpomoc a postupně jsme se dostali až ke{" "}
              <b>kompletní správě marketingu</b> a k výstavbě nového{" "}
              <b>e-shopu na míru.</b>
            </p>

            <Hashtags>
              #marketingová_strategie #e-shop #automatizace #komplexní_marketing
            </Hashtags>
          </BlockImageTextHalf>

          <BlockImageTextHalf
            image={ImageBlockIFE_1x}
            imageSrcSet={`${ImageBlockIFE_1x},${ImageBlockIFE_2x} 2x`}
            width="538"
            height="359"
            alt={"IFE - obrázek stroje"}
            buttonText={"Celý příběh"}
            buttonLink="/marketing/reference/ife"
          >
            <LogoHeading
              imagePath={LogoIfeSmall_1x}
              imageSrcSet={`${LogoIfeSmall_1x}, ${LogoIfeSmall_2x} 2x`}
              imageAlt="Logo IFE"
            >
              Pomáháme <TextPrimary>IFE</TextPrimary> obsadit desítky pozic díky
              online reklamě
            </LogoHeading>

            <p>
              Pro IFE pracujeme na komplexním řešení jejich <b>HR marketingu</b>
              . Zvýšili jsme <b>příliv životopisů v řádu desítek</b> a
              návštěvnost kariérních stránek v řádu tisíců měsíčně.
            </p>

            <Hashtags>#HR_marketing #branding #sociální_sítě #PPC</Hashtags>
          </BlockImageTextHalf>
        </Container>

        <ReferencesUsers title={"Naši zákazníci"} data={usersReferences} />

        <ReferencesNext
          data={marketingReferences}
          buttonLink="/marketing/reference"
        />

        <Slider title={"Poznejte náš tým"} data={personSlider} />

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}
